import { ArticleJsonLd, BreadcrumbJsonLd } from 'next-seo';

import { toAbsoluteLink } from 'lib/links';
import placeholder from 'lib/placeholder';

import APP from 'constants/app';
import LINKS from 'constants/links';

const BlogSchema = () => (
  <>
    <BreadcrumbJsonLd
      itemListElements={[
        {
          position: 1,
          name: placeholder('breadcrumbs.app'),
          item: APP.URL,
        },
        {
          position: 2,
          name: 'Blog',
          item: toAbsoluteLink(LINKS.BLOG),
        },
      ]}
    />
    <ArticleJsonLd
      description={placeholder('seo.blog.description')}
      title={placeholder('seo.blog.title')}
      url={toAbsoluteLink(LINKS.BLOG)}
    />
  </>
);

export default BlogSchema;
