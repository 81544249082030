import PropTypes from 'prop-types';

import Breadcrumb from 'shopper/components/Breadcrumb';

import BreadcrumbItem from 'components/Breadcrumb/Item';

import placeholder from 'lib/placeholder';

import BlogCategoryCarousel from './CategoryCarousel';

import LINKS from 'constants/links';

const BlogHeading = ({ categories, className, gaEventCategory, ...rest }) => (
  <>
    <div className={className} {...rest}>
      <Breadcrumb>
        <BreadcrumbItem
          data-test-selector="home-page-breadcrumb-item"
          href={LINKS.HOME}
        >
          {placeholder('breadcrumbs.promobit')}
        </BreadcrumbItem>
        <BreadcrumbItem>{placeholder('breadcrumbs.blog')}</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <BlogCategoryCarousel
      categories={categories}
      gaEventCategory={gaEventCategory}
    />
  </>
);

BlogHeading.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.number.isRequired,
      categoryName: PropTypes.string.isRequired,
      categorySlug: PropTypes.string.isRequired,
      categoryIcon: PropTypes.string.isRequired,
    })
  ).isRequired,
  gaEventCategory: PropTypes.string.isRequired,
};

export default BlogHeading;
